import React, {useState, useEffect} from 'react'

const ChatFooter = ({socket, username}) => {
    const [message, setMessage] = useState("")
    const handleTyping = () => socket.emit("typing",`${username} is typing`)
    const handleSendMessage = (e) => {
        e.preventDefault()
        if(message.trim() && username) {
        socket.emit("message", 
            {
            text: message, 
            name: username, 
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id
            }
        )
        }
        setMessage("")
    }
    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        socket.emit("typing", "")
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }, [socket, message])

  return (
    <form className='form' onSubmit={handleSendMessage}>
      <input 
        type="text" 
        placeholder='Type Something...' 
        className='message' 
        value={message} 
        onChange={e => {setMessage(e.target.value); handleTyping()}}
      />
      <a className="spacer"></a>
      <button class="button">
        <i class="icon-send material-icons">send</i>
      </button>
    </form>
  )
}

export default ChatFooter