import React, { useState, useEffect } from 'react'
import {useNavigate} from "react-router-dom"

const ChatBody = ({messages, typingStatus, lastMessageRef, socket, username}) => { 
  const navigate = useNavigate()

  const handleLeaveChat = () => {
    navigate("/")
    localStorage.removeItem("username")
    window.location.reload()
  }

  const [users, setUsers] = useState("")

  useEffect(()=> {
    socket.on("newUserResponse", data => {setUsers(data); console.log(data)})
  }, [socket])
  
  return (
    <>
      <header className='chat__mainHeader'>
        <p>Active User{users > 1 ? "s":""}: {users}</p>
        <button className='leaveChat__btn' onClick={handleLeaveChat}>Leave Chat</button>
      </header>
      <div className='message__container'>
        {messages.map(message => (
          message.name === username ? (
            <div className="message__chats" key={message.id}>
              <p className='sender__name'>You</p>
              <div className='message__sender'>
                <p>{message.text}</p>
              </div>
            </div>
          ): (
            <div className="message__chats" key={message.id}>
              <p>{message.name}</p>
              <div className='message__recipient'>
                  <p>{message.text}</p>
              </div>
            </div>
          )
        ))}
        <div className='message__status'>
          <p>{typingStatus}</p>
        </div>
        <div ref={lastMessageRef} />   
      </div>
    </>
  )
}

export default ChatBody