import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"

const Home = ({}) => {
  const navigate = useNavigate()
  const [userName, setUserName] = useState("")

  const handleSubmit = (e) => {
      e.preventDefault()
      localStorage.setItem("username", userName)
      navigate("/chat")
  }
  return (
    <div className="homeForm">
      <form id="login-form" onSubmit={handleSubmit}>
        <img className="logo" src="https://convos.redsteroid.com/logo.png"/>
        <h1>Login</h1>
        <div className="input">
            <input type="text" 
            minLength={2} 
            placeholder=' '
            value={userName} 
            onChange={e => setUserName(e.target.value)}/>
            <label>Username</label>
        </div>
        <button disabled={userName.length<2}>
            <svg viewBox="0 0 16 16">
                <circle stroke-opacity=".1" cx="8" cy="8" r="6"></circle>
            </svg>
            <span>Submit</span>
        </button>
      </form>
    </div>
  )
}

export default Home