import React, { useEffect, useState, useRef} from 'react'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import {useNavigate} from "react-router-dom"

const ChatPage = ({socket}) => { 
  const username = localStorage.getItem("username")
  const navigate = useNavigate()
  if (!username) navigate("/")
  const [messages, setMessages] = useState([])
  const [typingStatus, setTypingStatus] = useState("")
  const lastMessageRef = useRef(null);

  socket.emit("newUser")

  useEffect(()=> {
    socket.on("messageResponse", data => setMessages([...messages, data]))
  }, [socket, messages])

  useEffect(()=> {
    socket.on("typingResponse", data => setTypingStatus(data))
  }, [socket])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  return (
    <div className="chat">
      <ChatBody messages={messages} typingStatus={typingStatus} lastMessageRef={lastMessageRef} socket={socket} username={username}/>
      <ChatFooter socket={socket} username={username}/>
    </div>
  )
}

export default ChatPage